import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";
import { useNavigate, useParams } from "react-router-dom";

interface LogoProps {
  onClick?: () => void;
}
export const Logo = ({ onClick }: LogoProps) => {
  const isMd = useMatchesBreakpoint("lg");
  const navigate = useNavigate();

  if (!isMd) {
    return (
      <div
        className="flex justify-center items-center cursor-pointer"
        onClick={() => {
          (onClick && onClick()) || navigate("overview");
        }}
      >
        <div className="flex flex-col items-center">
          <div className="flex">
            <div className="bg-capGreen rounded-full w-4 h-4"></div>
            <div className="bg-capGreen rounded-full w-4 h-4"></div>
          </div>
          <div className="flex">
            <div className="bg-capGreen rounded-full w-4 h-4"></div>
            <div className="bg-capGreen rounded-full w-4 h-4"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="h-10 cursor-pointer flex justify-start"
      onClick={() => {
        (onClick && onClick()) || navigate("overview");
      }}
    >
      <img
        className="h-full object-scale-down"
        src={`${process.env.PUBLIC_URL}/capguard-logo.png`}
        alt="logo"
      />
    </div>
  );
};
