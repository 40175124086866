import { ReactNode } from "react";
import { Modal } from "components/Modal/Modal";

export interface InfoBoxProps {
  info: ReactNode;
  onClose: () => void;
}
export const InfoBox = ({ info, onClose }: InfoBoxProps) => {
  return (
    <Modal showCloseButton onClose={onClose}>
      {info}
    </Modal>
  );
};
