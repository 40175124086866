import { HTMLProps, ReactNode, useState } from "react";
import classNames from "classnames";
import { InfoBox } from "components/InfoBox/InfoBox";
import { FaQuestion } from "react-icons/fa6";

interface CardProps extends HTMLProps<HTMLDivElement> {
  heading?: ReactNode;
  children?: ReactNode;
  info?: ReactNode;
}
export const Card = ({ children, heading, info, ...rest }: CardProps) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <>
      {showInfo && <InfoBox info={info} onClose={() => setShowInfo(false)} />}
      <div
        className={classNames(
          "bg-white w-full flex flex-col justify-start rounded-xl p-2",
          rest.className
        )}
        style={rest.style}
      >
        {heading && (
          <div className="relative w-full font-semibold">
            {heading}
            {info && (
              <div className="absolute right-0 top-0">
                <FaQuestion
                  className="border rounded-full p-1 text-2xl cursor-pointer"
                  onClick={() => setShowInfo(true)}
                />
              </div>
            )}
          </div>
        )}
        {children}
      </div>
    </>
  );
};
