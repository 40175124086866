import { ReactNode, useEffect, useRef, useState } from "react";
import { ReactComponent as Arrow } from "assets/pil.svg";
import classNames from "classnames";
import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";
import { createPortal } from "react-dom";
import { IoMdClose } from "react-icons/io";

interface ModalProps {
  children: ReactNode;
  onClose: () => void;
  showCloseButton?: boolean;
}

const setPadding = () => {
  const hasVerticalScrollbar = window.innerWidth > document.documentElement.clientWidth;
  if (!hasVerticalScrollbar) return;
  const navbar = document.getElementById("capguard-navbar");
  if (navbar) {
    navbar.style.paddingRight = "15.7px";
  }
  document.body.style.overflowY = "hidden";
  document.body.style.paddingRight = "17px";
};
const removePadding = () => {
  const navbar = document.getElementById("capguard-navbar");
  if (navbar) {
    navbar.style.paddingRight = "";
  }
  document.body.style.overflowY = "auto";
  document.body.style.paddingRight = "";
};

export const Modal = ({ children, onClose, showCloseButton }: ModalProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setIsLoaded(false);
    setTimeout(() => {
      onClose();
      removePadding();
    }, 400);
  };
  useEffect(() => {
    const closing = () => {
      setIsLoaded(false);
      setTimeout(() => {
        onClose();
        removePadding();
      }, 400);
    };
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        closing();
      }
    };
    modalRef?.current?.focus();
    setIsLoaded(true);

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      removePadding();
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);
  setPadding();
  const root = document.getElementById("root");
  const isSm = useMatchesBreakpoint("sm");

  return (
    root &&
    (isSm
      ? createPortal(
          <>
            <div
              className={classNames(
                "z-[1000] fixed top-0 left-0 h-full w-full bg-[#21302180] transition-opacity duration-500",
                { "opacity-0": !isLoaded },
                { "opacity-100": isLoaded }
              )}
            ></div>
            <div
              onClick={handleClose}
              className="z-[1001] select-none flex justify-center items-center w-full h-full fixed top-0 left-0 bg-transparent"
            >
              <div
                ref={modalRef}
                role="dialog"
                aria-modal="true"
                onClick={(e) => e.stopPropagation()}
                className={classNames(
                  "rounded-xl relative border border-capGreen shadow-lg bg-white p-2 min-w-[200px] min-h-[200px] transition-all duration-500",
                  { "opacity-0 -translate-y-12": !isLoaded },
                  { "opacity-100 translate-y-0": isLoaded }
                )}
              >
                {showCloseButton && (
                  <div onClick={() => onClose()} className="absolute z-[10002] right-0 top-0 p-2">
                    <IoMdClose className="text-3xl cursor-pointer border rounded-full p-1 hover:bg-capGreen hover:text-capLightGreen" />
                  </div>
                )}
                {children}
              </div>
            </div>
          </>,
          root
        )
      : createPortal(
          <div
            className={classNames(
              "z-[99999] fixed h-full top-0 left-0 w-full bg-white transition-transform duration-300",
              isLoaded ? "translate-x-none" : "translate-x-full"
            )}
          >
            <div className="px-8 py-4 flex flex-col justify-between h-full">
              <div className="flex justify-between w-full pb-4">
                <Arrow onClick={handleClose} className="h-8 w-8 fill-black cursor-pointer" />
              </div>
              <div className="h-full">{children}</div>
            </div>
          </div>,
          root
        ))
  );
};
