import { ReactNode } from "react";
import { Portfolio, useGetContactInfo } from "api/initial/useGetContactInfo";
import classNames from "classnames";
import { Logo } from "components/Logo/Logo";
import { PortfolioSelect } from "components/PortfolioSelect/PortfolioSelect";
import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import { NavLink } from "react-router-dom";
import { UserMenu } from "./UserMenu";

export const Navbar = () => {
  const isMd = useMatchesBreakpoint("lg");

  return (
    <nav className="fixed top-0 left-0 margin h-[var(--navbar-height)] w-full bg-capBeige z-[1000]">
      <div id="capguard-navbar" className="w-full h-full flex flex-col justify-start">
        <div className="w-full h-full flex items-start pt-8">
          <div className="w-full flex-1 h-auto lg:h-12 flex justify-between items-center">
            <div className="flex justify-start items-center gap-6">
              <Logo />
              <PortfolioSelect />
            </div>
            <div className="flex flex-1 justify-end items-center">
              <UserMenu />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-start items-center border-b border-capGreen bg-capBeige gap-4 py-2 lg:py-4 lg:pb-8 overflow-x-scroll overflow-y-hidden no-scrollbar">
          {isMd && <NavItems />}
        </div>
      </div>
    </nav>
  );
};

const hasEkb = (portfolios: Portfolio[]) => {
  return portfolios.some(
    (pf) =>
      pf.modelPortfolio?.code.includes("EKB") ||
      pf.modelPortfolio?.linkedPortfolio?.shortName?.includes("EKB")
  );
};
const userIsAllowed = (username: string | undefined) => {
  return username?.toLocaleLowerCase().startsWith("inv") || false;
};

interface NavItemsProps {
  onClick?: () => void;
}
export const NavItems = ({ onClick }: NavItemsProps) => {
  const { t } = useModifiedTranslation();
  const { userProfile } = useKeycloak();
  const userIsInternal = userIsAllowed(userProfile?.username);
  const { selectedContactId } = useGetContractIdData();
  const { data, loading } = useGetContactInfo(false, selectedContactId);
  const userHasEkb = data && userIsInternal && hasEkb(data.portfolios);

  return (
    <>
      <NavElement onClick={onClick} to="overview">
        {t("navTab.tabs.overview")}
      </NavElement>
      <NavElement onClick={onClick} to="holdings">
        {t("navTab.tabs.holdings")}
      </NavElement>
      <NavElement onClick={onClick} to="transactions">
        {t("navTab.tabs.transactions")}
      </NavElement>
      <NavElement onClick={onClick} to="orders">
        {t("navTab.tabs.orders")}
      </NavElement>
      <NavElement onClick={onClick} to="documents">
        {t("navTab.tabs.documents")}
      </NavElement>
      <NavElement onClick={onClick} to="profile">
        {t("navTab.tabs.profile")}
      </NavElement>
      <NavElement onClick={onClick} to="contact">
        {t("navTab.tabs.contact")}
      </NavElement>
      {userIsInternal && (
        <NavElement onClick={onClick} to="analysis">
          {t("navTab.tabs.analysis")}
        </NavElement>
      )}
      {userHasEkb && (
        <NavElement onClick={onClick} to="ekb">
          {t("navTab.tabs.ekb")}
        </NavElement>
      )}
    </>
  );
};

interface NavElementProps {
  to: string;
  children: ReactNode;
  onClick?: () => void;
}
const NavElement = ({ to, children, onClick }: NavElementProps) => {
  return (
    <NavLink
      to={to}
      onClick={onClick}
      className={({ isActive }) =>
        classNames("cursor-pointer whitespace-nowrap", {
          "!font-extrabold underline": isActive,
        })
      }
    >
      {children}
    </NavLink>
  );
};
