import { gql, useQuery } from "@apollo/client";
import { Representee } from "./useGetContactInfo";

interface RepresenteesQuery {
  contacts: Representee[];
}

export const REPRESENTEES_QUERY = gql`
  query GetRepresenteesQuery($sizeLimit: Int) {
    contacts(includeContactType: true, status: "A", contactTypeCode: "1", resultSize: $sizeLimit) {
      id
      contactId
      name
      status
    }
  }
`;

export const useGetRepresentees = (getReps: boolean, callApi = false) => {
  const { loading, error, data, refetch } = useQuery<RepresenteesQuery>(REPRESENTEES_QUERY, {
    fetchPolicy: callApi ? "cache-and-network" : "cache-first",
    variables: { sizeLimit: getReps ? null : 0 },
  });

  return {
    loading: loading,
    error: error,
    data:
      data?.contacts.filter(
        (x) => x.status === "A" && (x.contactId.startsWith("F") || x.contactId.startsWith("A"))
      ) || [],
    refetch,
  };
};
